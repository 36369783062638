// EN 英文
export default {

  // logo区域
  logoArea_text_welcome: "欢迎您",
  logoArea_text_myBalance: "余额:",
  logoArea_btn_register: "注册",
  logoArea_btn_login: "登录",
  logoArea_btn_recharge: "充值",
  logoArea_btn_logout: "注销",

  // 右上方标题区
  headerArea_btn_exitChat_title: "关闭聊天",
  headerArea_btn_history_title: "打开历史聊天",
  headerArea_btn_save_title: "保存聊天",

  // 聊天区域
  charArea_item_notSupportAudio: "您的浏览器不支持音频播放",
  charArea_item_historyFileExpire: "历史生成的文件已过期",

  // 文件上传区域
  uploadArea_btn_uploadFile_defaultText: "上传文件",

  // 命令输入区域
  inputPanelArea_default_cmdRunFail: "运行失败",
  inputPanelArea_cmdConfigNotFound: "命令不存在",
  inputPanelArea_cmdNotFound: "命令未找到！",
  inputPanelArea_cmdNotNnderstand: "系统无法理解您的意图，请重试！",
  inputPanelArea_btn_text_send: "发送",
  inputPanelArea_btn_text_sending: "发送中",

  pleaseLoginAfterUse: "请先登录后再调用服务",
  registerSuccess: "用户注册成功",
  serviceFail: "接口请求失败",
  loginExpired: "登录过期，请重新登录。",
  insufficientBalance: "余额不足，请充值。",
  
  // 确认框
  confirmed_exitChat_title: "您确定要关闭聊天吗？",
  confirmed_exitChat_content: "聊天正在进行中，强行关闭将导致聊天记录丢失。建议先保存后关闭。",
  confirm_overlapChat_title: "历史聊天记录[{{saveName}}]已经存在，确定覆盖吗？",
  confirm_overlapChat_content: "聊天正在进行中，强行关闭会导致聊天记录丢失，建议先保存后关闭。",

  // 气泡弹窗
  pop_input_saveName_placeholder: "请输入保存名称，按<Enter>键保存",

  // 普通弹窗
  modal_title_userLogin: "用户登录",
  modal_title_userRegister: "用户注册",
  modal_title_newUser: "欢迎光临！",
  modal_title_newRechange: "会员充值",
  nodal_content_newUser: "Dalipen是一家致力于推进多媒体技术的公司，在这里，您可以选择您想要的功能。为了帮助您入门，我们为您准备了 1000 个代币。",
  modal_btn_newUser: "让我们开始吧！",
  modal_btn_okText: "确定",
  modal_btn_cancelText: "取消",
  modal_btn_loginText: "登录",
  modal_btn_rechange: "充值",
  modal_btn_getEmailCodeText: "获取邮箱验证码",
  modal_btn_registerText: "注册",
  modal_input_rechangeMoney_placeholder: "请输入充值金额",
  modal_form_email_label: "邮箱",
  modal_form_email_errorMsg: "请输入邮箱！",
  modal_form_password_label: "密码",
  modal_form_password_errorMsg: "请输入密码！",
  modal_form_code_label: "验证Code",
  modal_form_code_errorMsg: "请输入您的验证码！",

  // 消息提示
  message_save_success: "保存成功",
  message_warn_isChatting: "正在聊天，建议先保存",
  message_error_rechange1: "请输入充值金额",
  message_error_rechange2: "请输入正确金额，最小为1",

  // 支付结果页面
  pay_result_success: "支付成功",
  pay_result_success_message: "感谢您的购买！",
  pay_result_fail: "支付失败",

  // 菜单区域
  menu_text: "文本",
  menu_text_10001: "SellMeThisPen",
  menu_text_10002: "Tiktok短视频文案",
  menu_text_10003: "翻译",
  menu_video: "视频",
  menu_video_20001: "模板",
  menu_video_20002: "文本转模板",
  menu_video_20003: "数字人",
  menu_audio: "音频",
  menu_audio_30001: "TTS",
  menu_audio_30002: "语音转文字",
  menu_audio_30003: "人声分离",
  menu_audio_30004: "语音克隆",
  menu_image: "图片",
  menu_image_40001: "文生图(SDXL)",
  menu_image_40002: "文生图(Ideogram)",
  menu_image_40003: "文生图(SD3)",
  menu_image_40004: "换脸",

  //命令设置文件
  command_clean_desc: "清除当前对话框",
  command_content_desc: "字段型命令，用于配置输出文本内容",
  command_mp3Upload_desc: "上传音频MP3文件",
  command_mp3Upload_okMsg: "音频文件已上传上传成功。\n{{audio:value}}",
  command_mp3Upload_errorMsg: "上传音频文件失败，请重试",
  command_mp3Upload_ext_buttonText: "上传音频MP3文件",
  command_imageUpload_desc: "上传图片文件",
  command_imageUpload_okMsg: "图片文件已上传上传成功。\n{{image:value}}",
  command_imageUpload_errorMsg: "上传图片文件失败，请重试",
  command_imageUpload_ext_buttonText: "上传图片文件",
  command_pitch_desc: "字段类型命令，用于配置输出音调（默认值:0）",
  command_pitch_okMsg: "指定的语调:{{value}}",
  command_pitch_errorMsg: "值类型或格式不正确，必须为数字",
  command_queryTrain_desc: "查询训练任务结果（需先执行/train_tts命令）",
  command_queryTrain_errorMsg: "查询失败",
  command_empty_desc: "",
  command_empty_okMsg: "",
  command_empty_errorMsg: "",
  command_separator_desc: "分隔符语音（需先执行/separator_upload命令）",
  command_separator_okMsg: "启动成功",
  command_separator_errorMsg: "启动失败，请重试",
  command_speechToText_desc: "上传语音文件并自动转为文字",
  command_speechToText_okMsg: "",
  command_speechToText_errorMsg: "",
  command_upload_a_video_ext_buttonText: "上传视频文件(.mp4)",
  command_upload_a_video_desc: "上传一个视频(.mp4)",
  command_upload_a_video_okMsg: "",
  command_upload_a_video_errorMsg: "",
  command_upload_a_audio_ext_buttonText: "上传音频文件(.mp3|.wav)",
  command_upload_a_audio_desc: "上传一个音频(.mp3|.wav)",
  command_upload_a_audio_okMsg: "",
  command_upload_a_audio_errorMsg: "",
  command_set_prompt_desc: "输入想让生成视频的prompt (中文)",
  command_set_prompt_okMsg: "",
  command_set_prompt_errorMsg: "",
  command_digital_human_desc: "生成中...",
  command_digital_human_okMsg: "开始生成",
  command_digital_human_errorMsg: "失败",
  command_speaker_desc: "字段型命令，弹出下拉框选择音调",
  command_speaker_okMsg: "指定音色:",
  command_language_okMsg: "指定语言:",
  command_speed_desc: "字段型命令，用于配置速度（默认:1）",
  command_speed_okMsg: "指定速度:{{value}}",
  command_speed_errorMsg: "值类型或格式不正确，必须为数字",
  command_trainTts_desc: "训练语音（需先执行/train_upload命令）",
  command_trainTts_okMsg: "开始训练成功",
  command_trainTts_errorMsg: "未能成功开始训练，请重试",
  command_trainUpload_desc: "上传训练语音MP3文件",
  command_trainUpload_okMsg: "训练资源文件上传成功\n{{audio:value}}",
  command_trainUpload_errorMsg: "训练资源文件上传失败，请重试",
  command_trainUpload_ext_buttonText: "上传MP3资源文件",
  command_tts_desc: "生成语音（需要先执行/speaker和/content命令）",
  command_tts_okMsg: "AI音频输出:\n{{audioBlob}}",
  command_tts_errorMsg: "AI音频输出失败",
  command_volume_desc: "字段类型命令，用于配置输出音量（默认值:1）",
  command_volume_okMsg: "指定的音量:{{value}}",
  command_volume_errorMsg: "值类型或格式不正确，必须为数字！",

  command_separatorUpload_desc: "上传MP3文件",
  command_separatorUpload_okMsg: "文件上传成功\n{{audio:value}}",
  command_separatorUpload_errorMsg: "上传资源文件失败，请重试",
  command_separatorUpload_ext_buttonText: "上传 MP3 资源文件",

  // 页面链接
  page_link_aboutUs: "关于我们",
  page_link_privacyPolicy: "隐私政策",

}