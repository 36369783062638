import { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import '@/less/global.less';

const Home = lazy(() => import("./pages/home"));
const PayResult = lazy(() => import("./pages/payResult"));

window.env = "dev";
window.apiPrefix = "https://api.dalipen.com"; // "/api/dev";
if (window.location.host === "chat-test.dalipen.com") {
  window.env = "test"
  window.apiPrefix = "/api/test";
}
if (window.location.host === "chat.dalipen.com") {
  window.env = "prod"
  window.apiPrefix = "https://api.dalipen.com";
}
window.initRoute = "/home";
window.country = "US";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

(async () => {
  let result: any = await fetch(window.apiPrefix + "/whereami?t=" + new Date().getTime());
  result = await (result).json();
  if (result.code === 0) {
    window.country = result.data;
  }

  let antdLang;  // antd 依赖的语言包
  let dayjsLang;  // dayjs 依赖的语言包

  switch (window.country) {
    case "CN":
    case "TW":
      window.lang = require('@/assets/locale/zh').default;
      antdLang = require('antd/es/locale/zh_CN').default;
      dayjsLang = require('dayjs/locale/zh-cn').default;
      break;
    // case "TW":
    //   window.lang = require('@/assets/locale/zh-Hant').default;
    //   antdLang = require('antd/es/locale/zh_TW').default;
    //   dayjsLang = require('dayjs/locale/zh-tw').default;
    //   break;
    // case "ID":
    //   window.lang = require('@/assets/locale/id').default;
    //   antdLang = require('antd/es/locale/id_ID').default;
    //   dayjsLang = require('dayjs/locale/id').default;
    //   break;
    // case "IN":
    //   window.lang = require('@/assets/locale/hi').default;
    //   antdLang = require('antd/es/locale/en_US').default; // Assuming antd doesn't have specific locale for Hindi
    //   dayjsLang = require('dayjs/locale/hi').default;
    //   break;
    // case "ES":
    //   window.lang = require('@/assets/locale/es').default;
    //   antdLang = require('antd/es/locale/es_ES').default;
    //   dayjsLang = require('dayjs/locale/es').default;
    //   break;
    // case "TR":
    //   window.lang = require('@/assets/locale/tr').default;
    //   antdLang = require('antd/es/locale/tr_TR').default;
    //   dayjsLang = require('dayjs/locale/tr').default;
    //   break;
    // case "TH":
    //   window.lang = require('@/assets/locale/th').default;
    //   antdLang = require('antd/es/locale/th_TH').default;
    //   dayjsLang = require('dayjs/locale/th').default;
    //   break;
    // case "JP":
    //   window.lang = require('@/assets/locale/ja').default;
    //   antdLang = require('antd/es/locale/ja_JP').default;
    //   dayjsLang = require('dayjs/locale/ja').default;
    //   break;
    // case "PT":
    //   window.lang = require('@/assets/locale/pt').default;
    //   antdLang = require('antd/es/locale/pt_PT').default;
    //   dayjsLang = require('dayjs/locale/pt').default;
    //   break;
    // case "MY":
    //   window.lang = require('@/assets/locale/ms').default;
    //   antdLang = require('antd/es/locale/ms_MY').default;
    //   dayjsLang = require('dayjs/locale/ms').default;
    //   break;
    // case "KR":
    //   window.lang = require('@/assets/locale/ko').default;
    //   antdLang = require('antd/es/locale/ko_KR').default;
    //   dayjsLang = require('dayjs/locale/ko').default;
    //   break;
    // case "FR":
    //   window.lang = require('@/assets/locale/fr').default;
    //   antdLang = require('antd/es/locale/fr_FR').default;
    //   dayjsLang = require('dayjs/locale/fr').default;
    //   break;
    // case "RU":
    //   window.lang = require('@/assets/locale/ru').default;
    //   antdLang = require('antd/es/locale/ru_RU').default;
    //   dayjsLang = require('dayjs/locale/ru').default;
    //   break;
    // case "DE":
    //   window.lang = require('@/assets/locale/de').default;
    //   antdLang = require('antd/es/locale/de_DE').default;
    //   dayjsLang = require('dayjs/locale/de').default;
    //   break;
    default:
      window.lang = require('@/assets/locale/en').default;
      antdLang = require('antd/es/locale/en_US').default;
      dayjsLang = require('dayjs/locale/en').default;
      break;
  }

  root.render(
    // <React.StrictMode>
    <ConfigProvider locale={antdLang}>
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<Home />} />
            <Route path="/pay_success.html" element={<PayResult result="success" />} />
            <Route path="/pay_cancel.html" element={<PayResult result="fail" />} />
          </Routes>
        </BrowserRouter>
      </StyleProvider>
    </ConfigProvider >
    // </React.StrictMode>
  );
})();
